:root {
  --background-color: rgba(0, 0, 0, 0.87);
  --background-color_pay: #ff8a00;
  --primary-font: 'Roboto', sans-serif;
  --primary-font-small: 14px;
  --primary-font-middle: 16px;
  --primary-font-extra-middle: 20px;
  --primary-font-extra: 24px;
  --primary-font-extra-large: 32px;
  --primary-font-extra-large-main: 40px;
  --primary-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  --primary-hr-color: rgba(0, 0, 0, 0.4);
}

body {
  padding:0;
  margin:0;
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
p {
  margin: 0;
  padding: 0;
}

code {
  font-family: var(--primary-font);;
}

header {
  background-color: #0000002e;
  padding:16px
}

header p {
  display:none
}

main {
  display:flex;
  justify-content: center;
}

#receipt {
  margin: 32px;
  width: 412px;
  box-shadow: var(--primary-box-shadow);
  padding: 0 16px 24px;
}

#receiptHeader {
  text-align: center;
  margin-top: 68px;
}

.orderType {
  font-size: var(--primary-font-extra-middle);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
}

.separator {
  margin-bottom: 12px;
  margin-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
}

.orderNoteBlock {
  margin: 0 auto;
  max-width: 600px;
  display: flex;
  align-items: flex-start
}
.instructions {
  font-size: var(--primary-font-small);
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  font-style: italic;
}
.note {
  font-size: var(--primary-font-middle);
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
}

.displayNumber {
  font-size: var(--primary-font-extra-large-main);
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
}

.displayRefund {
  font-size: var(--primary-font-extra-large-main);
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 8px;
}

.companyName {
  font-size: var(--primary-font-extra);
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 12px;
}
.address_info {
  font-size: var(--primary-font-small);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  text-align: center;
  margin: 8px auto 0;
  text-transform: uppercase;
}
.phone-number {
  font-size: var(--primary-font-small);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  text-align: center;
}

.line-header {
  background: var(--primary-hr-color);
  padding: 0;
  margin-top: 12px;
  margin-bottom: 12px;
}
.line-header-long {
  background: var(--primary-hr-color);
  padding: 0;
  margin-top: 12px;
  margin-bottom: 12px;
}
.user-message {
  font-size: var(--primary-font-middle);
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  margin-top: 16px;
}
.order-number {
  font-size: var(--primary-font-small);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
}

.business-logo {
  margin-top: 12px;
  img {
    width: 98px;
    height: 98px;
  }
}
.uppercase {
  text-transform: uppercase;
}
.cashierInfo {
  text-align: left;
  font-size: var(--primary-font-small);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87)
}

.make-padding {
  padding-top: 8px;
}

/*--------------------------------Order Delivery--------------------------*/

.delivery-title {
  font-weight: bold;
  font-size: var(--primary-font-middle);
  line-height: 18px;
  letter-spacing: 0.1px;
  margin: 0 0 8px;
  color: rgba(0, 0, 0, 0.87)
}
.delivery-wrapper {
  display: flex;
  width: 100%;
}
.delivery-wrapper-left {
  width: 100%;
  min-width: 220px;
  font-size: var(--primary-font-small);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
}
.delivery-wrapper-right {
   width: 100%;
   max-width: calc(100% - 220px);
   font-size: var(--primary-font-small);
   line-height: 16px;
   letter-spacing: 0.1px;
   color: rgba(0, 0, 0, 0.87);
}

.payment-wrapper-left {
  width: 100%;
  max-width: 170px;
  margin: 0.5px 0;
}
.payment-wrapper-right {
  width: 100%;
  max-width: calc(100% - 170px);
  margin: 0.5px 0;
  word-wrap: break-word;
}
.thankYou-text {
  font-size: var(--primary-font-middle);
  font-weight: 700;
  line-height: 20px;
  color: var(--background-color);
}
.thankYou-text-second {
  font-size: var(--primary-font-middle);
  font-weight: 400;
  line-height: 20px;
  color: var(--background-color);
}

/*--------------------------------Order Details --------------------------*/

.order-details-title {
  font-size: var(--primary-font-middle);
  font-weight: 600;
  line-height: 4px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  margin-top: 24px;
}
.order-details-business {
  margin-top: 16px;
  display: flex;
  gap: 8px;
}
.order-details-address-name {
  font-size: var(--primary-font-middle);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}

.order-details-address-currentAddress {
  font-size: var(--primary-font-middle);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  margin-top: 7px;
}

/*--------------------------------Order Modifier--------------------------*/
table {
  width:100%;
  margin-top: -2px;
}
table tr {
  display:flex;
  justify-content:space-between
}

.modifier-name {
  margin-left: 29px;
  font-size: var(--primary-font-small);
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}

.modifier-name > div {
  display: flex;
  gap: 5px;
}

.modifier-name p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
}

.modifier-main-name {
  margin: 0 0 0 29px;
  font-size: var(--primary-font-small);
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}

/*--------------------------------Order Charges--------------------------*/

.global-total {
  font-weight: 600;
  font-size: var(--primary-font-extra-middle);
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}
.totals-price-size {
  font-weight: 400;
  font-size: var(--primary-font-middle);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}

.product {
  font-weight: 500;
  font-size: var(--primary-font-small);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  margin-top: 0;
}

.product-name {
  font-weight: 510;
  font-size: var(--primary-font-small);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}


.categoryName {
  font-weight: 700;
  font-size: var(--primary-font-middle);
  line-height: 20px;
  letter-spacing: 0.1px;
  color: rgba(15, 18, 20, 1);
  margin-bottom: 4px;
}

.product-total {
  font-weight: 600;
  font-size: var(--primary-font-middle);
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--background-color);
  margin-top: 0;
}
.items {
  font-weight: 600;
  font-size: var(--primary-font-middle);
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}

.total td:nth-child(odd) {
  font-weight: 400;
  font-size: var(--primary-font-middle);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}
.total td:nth-child(even) {
  font-weight: 400;
  font-size: var(--primary-font-middle);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}
.tip td:nth-child(odd) {
  font-weight: 400;
  font-size: var(--primary-font-middle);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}
.tip td:nth-child(even) {
  font-weight: 400;
  font-size: var(--primary-font-middle);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}
.payment-container {
  display: flex;
  gap: 16px;
}
.payment-title {
  /*margin-bottom: 20px;*/
}
.payment-info {
  margin-bottom: 8px;
}
.cart-number {
  margin-top: 8px;
}
.payment-title,.cart-number-title,.cart-number {
  font-weight: 400;
  font-size: var(--primary-font-middle);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--background-color);
}

.payment-container-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-name-longer {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 320px;
}

.pay {
  background: var(--background-color_pay);
  color: white;
  border-radius: 6px;
  padding: 8px 22px 8px 22px;
  font-size: var(--primary-font-middle);
  font-weight: 700;
  border: none;
  height: 44px;
  width: 100%;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .product-name-longer {
    display: -webkit-box;
    /*-webkit-line-clamp: 1;*/
    -webkit-box-orient: vertical;
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*white-space: normal;*/
    width: 240px;
  }
  .product-longer-price {
    /*width: 65px;*/
  }
}


@media screen and (max-width: 450px) {
  .modifier-name p {
    max-width: 133px;
  }
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  background-color: #f7f7f7;
}

.accordion-header .items {
  font-size: 16px;
  font-weight: bold;
}

.accordion-header span {
  font-size: 24px;
  line-height: 16px;
}

/* Items Container Animation */
.items-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.items-container.open {
  max-height: 20000px; /* Arbitrary large value to accommodate content */
  opacity: 1;
  transition: max-height 2s ease-in, opacity 0.3s ease-in;
}

/* Optional: Smooth transition for the open/close arrow */
.accordion-header span {
  transition: transform 0.3s ease;
}